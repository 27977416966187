"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getFeesText;
const gbp = require('gbp');
const stdText = 'Standard Fees Apply';
const covidText = 'COVID 19: Entry Fee to be confirmed when entries open';
const enhancedPrefix = 'Entry Fee';
function getFeesText(divs) {
  if (!divs || !divs.length) return [];
  if (divs.every(d => d.useStandardFees)) return [stdText];
  if (divs.every(d => d.enhancedFeeTbd)) return [covidText];
  if (divs[0].enhancedFee && divs[0].enhancedFee > 0 && divs.every(d => d.enhancedFee === divs[0].enhancedFee)) return [enhancedPrefix + ': ' + gbp(divs[0].enhancedFee)];
  const feesText = [];
  divs.filter(d => d.useStandardFees || d.enhancedFeeTbd || d.enhancedFee).map(d => feesText.push(d.tierDescription + ': ' + (d.useStandardFees ? stdText : d.enhancedFeeTbd ? covidText : d.enhancedFee ? enhancedPrefix + ' ' + gbp(d.enhancedFee) : '')));
  return feesText;
}