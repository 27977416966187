"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.thisYear = exports.adminEmail = void 0;
// Note stripe constants and those associated with charging/payouts are in the stripe folder

// Year is for the current season year.  DO NOT UPDATE UNTIL
// The last slalom of the season has been paid out

// The ranking database extracts (bibs, paddlers, clubs are still based on calendar year)

// Remember to update standard entry fees and discounts and charges

const thisYear = exports.thisYear = 2025;

// adminEmail is predominantly used in help text
const adminEmail = exports.adminEmail = 'dee@lindesay.co.uk';