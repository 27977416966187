"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectOptions = selectOptions;
exports.selectQuery = selectQuery;
var _occasion = _interopRequireDefault(require("occasion"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _mergeBqlQueries(firstQuery, secondQuery) { if (secondQuery === null) { return firstQuery; } const result = {}; let firstQueryUpdated = false; let secondQueryUpdated = false; for (let key in firstQuery) { if (key in firstQuery) { if (!secondQuery[key]) { firstQueryUpdated = true; result[key] = firstQuery[key]; } else if (firstQuery[key] !== secondQuery[key]) { result[key] = _mergeBqlQueries(firstQuery[key], secondQuery[key]); if (result[key] !== secondQuery[key]) { firstQueryUpdated = true; secondQueryUpdated = true; } } else { result[key] = firstQuery[key]; } } } if (!firstQueryUpdated) { return secondQuery; } for (let key in secondQuery) { if (key in secondQuery && !result[key]) { secondQueryUpdated = true; result[key] = secondQuery[key]; } } return secondQueryUpdated ? result : firstQuery; }
function selectOptions(props) {
  if (!props) return [];
  switch (props.lookup) {
    case 'PaddlersByBib':
      // only expire promotees after the new bib has been issued.
      // Note C2 has 2 paddlers, so we sort out the options and then
      // use reduce to flatten the array.
      if (!props.bibsWithPaddlers || !props.loaded) return [];
      const bibPaddlers = props.bibsWithPaddlers.map(bib => bib.paddlers.map(p => {
        return {
          value: p.id,
          isExpired: bib.isExpired || bib.isPromoted && !!bib.promotedToBibID,
          label: (bib.bibNumber ? bib.bibNumber + ' - ' : '') + p.paddlerName + ' ' + p.ageCategoryDescription + ' ' + p.clubName
        };
      }));
      return bibPaddlers.reduce((acc, bib) => {
        return [...acc, ...bib];
      }, []).filter(option => !option.isExpired || option.value === props.value);
    case 'BibNumbers':
      if (!props.cseBibsByClassDivision) return [];
      // only expire promotees after the new bib has been issued.
      const options = props.cseBibsByClassDivision.map(bib => ({
        value: bib.id,
        label: (bib.bibNumber ? bib.bibNumber + ' - ' : '') + bib.bibName,
        isExpired: bib.isExpired || bib.isPromoted && !!bib.promotedToBibID
      }));
      // Allow non-ranked to be selected so that we can get additional inputs for postal entry paddlers
      if (props.withNotRanked) options.push({
        value: 0,
        label: 'Not Ranked'
      });
      return options.filter(option => !option.isExpired || option.value === props.value);
    case 'ApplicationsByDate':
      if (!props.applications) return [];
      return props.applications.map(app => {
        return {
          value: app.id,
          label: app.site.location,
          isExpired: false
        };
      });
    case 'Competitions':
      if (!props.competitions) return [];
      const today = new Date().toISOString().split('T')[0];
      return props.competitions.filter(c => (!props.date || c.competitionDays.some(d => d.dayDate === props.date)) && c.isPublished);
    case 'CompetitionDays':
      if (!props.competitionDays) return [];
      return props.competitionDays.map(day => ({
        value: day.id,
        label: props.showFullDay ? (0, _occasion.default)(day.dayDate, 'ddd D MMM YYYY') : (0, _occasion.default)(day.dayDate, 'dddd')
      }));
    case 'ClassDivisions':
      if (!props.options) return [];
      // exclude classdivisions that cannot enter online unless we have explicitly said to include them.
      return props.options.filter(option => !option.isExpired && (option.canEnterOnline || props.includeCannotEnterOnline) || option.value === props.value);
    case 'CoreTiers':
      if (!props.options) return [];
      return props.options.filter(option => !option.isExpired && option.isCoreTier || option.value === props.value);
    case 'CalendarTiers':
      if (!props.options) return [];
      return props.options.filter(option => !option.isExpired && option.isInCalendar || option.value === props.value);
    default:
      if (!props.options) return [];
      return props.options.filter(option => !option.isExpired || option.value === props.value);
  }
}

// *************************************************************
// Query for Data
// *************************************************************

// Although most of the codes can be set to value and label when fetching with
// bicycle, BibNumbers, Dates and ClassDivisions require additional manipulation
// - see _getOptions, above
var _ref = {
  id: true,
  bibNumber: true,
  bibName: true,
  isPromoted: true,
  promotedToBibID: true,
  isExpired: true
};
var _ref2 = {
  bibNumber: true,
  isPromoted: true,
  promotedToBibID: true,
  isExpired: true,
  paddlers: {
    id: true,
    paddlerName: true,
    ageCategoryDescription: true,
    clubName: true
  }
};
var _ref3 = {
  id: true,
  site: {
    location: true
  }
};
var _ref4 = {
  "feeAdjustmentRef as value": true,
  "feeAdjustmentDescription as label": true
};
var _ref5 = {
  "id as value": true,
  "rankingDivisionDescription as label": true,
  isExpired: true,
  canEnterOnline: true
};
var _ref6 = {
  "id as value": true,
  "shortName as label": true,
  isPublished: true,
  startDate: true,
  competitionDays: {
    dayDate: true
  }
};
var _ref7 = {
  id: true,
  dayDate: true
};
var _ref8 = {
  "tierRef as value": true,
  "tierDescription as label": true,
  isExpired: true
};
var _ref9 = {
  "tierRef as value": true,
  "tierDescription as label": true,
  isCoreTier: true,
  isExpired: true
};
var _ref10 = {
  "tierRef as value": true,
  "tierDescription as label": true,
  isInCalendar: true,
  isExpired: true
};
var _ref11 = {
  "entryTypes as options": {
    "id as value": true,
    "description as label": true,
    isExpired: true
  }
};
var _ref12 = {
  "clubs as options": {
    "id as value": true,
    "clubName as label": true
  }
};
var _ref13 = {
  "paddlerClubID as value": true,
  "clubName as label": true
};
var _ref14 = {
  "refCode as value": true,
  "description as label": true,
  isExpired: true
};
var _ref15 = {
  "sites as options": {
    "id as value": true,
    "location as label": true,
    isExpired: true
  }
};
var _ref16 = {
  "counties as options": {
    "id as value": true,
    "countyName as label": true,
    isExpired: true
  }
};
var _ref17 = {
  "refCodes(codeType:\"bibcolours\") as options": {
    "description as value": true,
    "description as label": true,
    isExpired: true
  }
};
function selectQuery(props) {
  switch (props.lookup) {
    case 'BibNumbers':
      if (!props.classDivisionID || !props.yearID) return;
      return {
        ["cseBibsByClassDivision(classDivisionID:" + JSON.stringify(+props.classDivisionID) + ",yearID:" + JSON.stringify(+props.yearID) + ")"]: _ref
      };
    case 'PaddlersByBib':
      if (!props.classDivisionID || !props.yearID) return;
      return {
        ["cseBibsByClassDivision(classDivisionID:" + JSON.stringify(+props.classDivisionID) + ",yearID:" + JSON.stringify(+props.yearID) + ") as bibsWithPaddlers"]: _ref2
      };
    case 'ApplicationsByDate':
      if (!props.yearID || !props.calendarDateID) return;
      return {
        ["applicationsByDate(calendarDateID:" + JSON.stringify(props.calendarDateID) + ",yearID:" + JSON.stringify(+props.yearID) + ") as applications"]: _ref3
      };
    case 'AvailableFeeAdjustments':
      if (!props.yearID || !props.tierRef) return;
      return {
        ["availableFeeAdjustmentsByYearAndTier(existingFeeAdjustmentRefs:" + JSON.stringify(props.existingFeeAdjustmentRefs || []) + ",tierRef:" + JSON.stringify(props.tierRef) + ",yearID:" + JSON.stringify(+props.yearID) + ") as options"]: _ref4
      };
    case 'ClassDivisions':
      if (!props.classRef) return;
      return {
        ["classDivisionsByClassRef(classRef:" + JSON.stringify(props.classRef) + ") as options"]: _ref5
      };
    case 'Competitions':
      return {
        ["competitions(yearID:" + JSON.stringify(+props.yearID) + ")"]: _ref6
      };
    case 'CompetitionDays':
      if (!props.competitionID) return;
      return {
        ["competitionDays(competitionID:" + JSON.stringify(props.competitionID) + ")"]: _ref7
      };
    case 'DefaultTiers':
      if (!props.yearID) return;
      return {
        ["defaultTiersByYear(yearID:" + JSON.stringify(+props.yearID) + ") as options"]: _ref8
      };
    case 'CoreTiers':
      if (!props.yearID) return;
      return {
        ["defaultTiersByYear(yearID:" + JSON.stringify(+props.yearID) + ") as options"]: _ref9
      };
    case 'CalendarTiers':
      if (!props.yearID) return;
      return {
        ["defaultTiersByYear(yearID:" + JSON.stringify(+props.yearID) + ") as options"]: _ref10
      };
    case 'EntryTypes':
      return _ref11;
    case 'OrganisingClubs':
      return _ref12;
    case 'PaddlerClubs':
      if (!props.yearID) return;
      return {
        ["paddlerClubs(yearID:" + JSON.stringify(+props.yearID) + ") as options"]: _ref13
      };
    case 'RefCodes':
      return {
        ["refCodes(codeType:" + JSON.stringify(props.codeType) + ") as options"]: _ref14
      };
    case 'Sites':
      return _ref15;
    case 'Counties':
      return _ref16;
    // Note that for colours we need the description as value
    case 'BibColours':
      return _ref17;
    default:
      throw new Error('Invalid lookup value ' + props.lookup);
  }
}